var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('v-app-bar',{attrs:{"app":""}},[_c('v-toolbar-title',[_vm._v(" Manim Interactive ")]),_c('v-row',{staticClass:"d-flex flex-row-reverse"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2",attrs:{"fab":"","dark":"","small":"","color":"#e07a5f","to":"/about"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-information ")])],1)]}}])},[_c('span',[_vm._v("About")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2",attrs:{"fab":"","dark":"","small":"","color":"#525893","to":"/"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-pencil ")])],1)]}}])},[_c('span',[_vm._v("Create")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2",attrs:{"fab":"","dark":"","small":"","color":"#81b29a","loading":_vm.downloadInProgress},on:{"click":function($event){return _vm.getVid()}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-download ")])],1)]}}])},[_c('span',[_vm._v("Download")])])],1)],1),_c('v-main',[_c('router-view',{ref:"appc",on:{"download-complete":function($event){return _vm.setDownloadInProgress(false)}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }